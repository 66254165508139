/* global chrome */

import React from 'react';
import style from './index.module.scss';

class TodoPage extends React.Component {
  render() {
    return (
      <div className={style.TodoPage}>
        <ul>
          <li>Animate in the title and request access button</li>
          <li>Animate explanation of extension</li>
          <li>Request Access Page</li>
          <li>
            Relpace google default sign-in button with a globally designed
            button
          </li>
        </ul>
      </div>
    );
  }
}

export default TodoPage;
