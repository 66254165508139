/* global chrome */

import React from 'react';
import style from './index.module.scss';

import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <div className={style.Footer}>
        <div>
          <Link className={style.FooterLink} to="/private_policy">
            Private Policy
          </Link>
          |
          <Link className={style.FooterLink} to="/terms_of_service">
            Terms of Service
          </Link>
        </div>
        <div className={style.FooterCopyright}>Foccused © 2021</div>
      </div>
    );
  }
}

export default Footer;
