/* global chrome */

import React from 'react';
import style from './index.module.scss';

class Header extends React.Component {
  render() {
    return (
      <div className={style.Header}>
        <div className={style.HeaderTitle}>
          FO<span className={style.HeaderTitleEmphasis}>CC</span>USED
        </div>
        <div
          title={'Go to memtive'}
          onClick={() => (window.location = 'https://memtive.com')}
        >
          <div className={style.HeaderSubTitle}>by memtive</div>
        </div>
      </div>
    );
  }
}

export default Header;
