const config = {
  baseURL: 'https://app.foccused.com/api/v1',
  chromeID: 'hikphdifagkccemondmeejlhbedblnko',
  googleClientID:
    '468582342430-psebhjpumaa74vs59qs0dpdpfspd6boq.apps.googleusercontent.com',
};

// const config = {
//   baseURL: 'http://localhost:5000/api/v1',
//   chromeID: 'hikphdifagkccemondmeejlhbedblnko',
//   googleClientID:
//     '468582342430-psebhjpumaa74vs59qs0dpdpfspd6boq.apps.googleusercontent.com',
// };

export default config;
