/* global chrome */

import React from 'react';
import style from './index.module.scss';

// https://uxplanet.org/the-ultimate-workflow-of-creating-scroll-based-animations-7366b670630
class TestPage extends React.Component {
  // componentDidMount() {
  //   var robotAnimWindow = document.getElementById('robotLottie'),
  //     robotAnimData = {
  //       wrapper: robotAnimWindow,
  //       animType: 'svg',
  //       loop: false,
  //       prerender: true,
  //       autoplay: false,
  //       path: '/json/ch1_robot.json',
  //     };
  //   var robotAnim = bodymovin.loadAnimation(robotAnimData);
  //   robotAnim.addEventListener('DOMLoaded', onRobotDOMLoaded);
  // }
  render() {
    return (
      <div className={style.TestPage}>
        <div id="robotLottie" class="animation"></div>
        <div id="trigger" class="scene"></div>
      </div>
    );
  }
}

export default TestPage;
