/* global chrome */
import React from 'react';
import style from './index.module.scss';

import { Link } from 'react-router-dom';

class SignUp extends React.Component {
  render() {
    return (
      <div className={style.SignUpContainer}>
        <div className={style.SignUp}>GET ACCESS</div>
      </div>
    );
  }
}

export default SignUp;
