/* global chrome */

import React from 'react';
import style from './index.module.scss';

import Content from '../../components/Content';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GoogleAuth from '../../components/GoogleAuth';
import Auth from '../../modules/Auth';

import axios from 'axios';
import config from '../../config';

import urljoin from 'url-join';

class HomePage extends React.Component {
  render() {
    return (
      <div className={style.HomePage}>
        <div className={style.HomePageMain}>
          <Header />
          <Content />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
