/* global chrome */

import React from 'react';
import style from './index.module.scss';

import GoogleAuth from '../../components/GoogleAuth';

import axios from 'axios';
import urljoin from 'url-join';

import Auth from '../../modules/Auth';
import config from '../../config';

class ListPage extends React.Component {
  constructor(props) {
    super(props);
    this.instance = axios.create({
      withCredentials: true,
    });
    this.task_name_input = React.createRef();
    this.task_description_input = React.createRef();
    this.state = {
      token: null,
      tasks: [],
      name: '',
      priority: 'p1',
      description: '',
    };
  }

  onSuccess(response) {
    this.setState(
      {
        token: response.accessToken,
      },
      () => {
        this.instance
          .get(config.baseURL + '/task', {
            params: {
              access_token: response.accessToken,
            },
          })
          .then((res) => {
            this.setState({
              tasks: res.data.tasks,
            });
          });
      }
    );
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  renderTasks() {
    return this.state.tasks.map((x, idx) => (
      <div key={'task_' + idx}>{JSON.stringify(x)}</div>
    ));
  }

  createTask() {
    this.instance
      .post(
        config.baseURL + '/task',
        {
          name: this.state.name,
          description: this.state.description,
          priority: this.state.priority,
        },
        {
          params: {
            access_token: this.state.token,
          },
        }
      )
      .then((res) => {
        this.setState({
          tasks: [...this.state.tasks, res.data.tasks],
          name: '',
          description: '',
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onChangePriorityValue(event) {
    this.setState({
      priority: event.target.value,
    });
  }

  render() {
    if (this.state.token) {
      return (
        <div>
          <h1>TASKS</h1>
          <div>{this.renderTasks()}</div>
          <div>
            <h1>ADD TASK</h1>
            <div>
              <input
                type={'text'}
                placeholder={'Task'}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
                ref={this.task_name_input}
              />
            </div>
            <div>
              <input
                type={'text'}
                placeholder={'Description'}
                value={this.state.description}
                onChange={this.handleDescriptionChange.bind(this)}
                ref={this.task_description_input}
              />
            </div>
            <h3>PRIORITY</h3>
            <div onChange={this.onChangePriorityValue.bind(this)}>
              <input type="radio" name="priority" value="p1" defaultChecked />
              <label>P1</label>
              <br />
              <input type="radio" name="priority" value="p2" />
              <label>P2</label>
              <br />
              <input type="radio" name="priority" value="p3" />
              <label>P3</label>
              <br />
              <input type="radio" name="priority" value="p4" />
              <label>P4</label>
              <br />
              <input type="radio" name="priority" value="p5" />
              <label>P5</label>
              <br />
            </div>
            <button onClick={this.createTask.bind(this)}>Create</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={style.ListPage}>
          <GoogleAuth onSuccess={this.onSuccess.bind(this)} />
        </div>
      );
    }
  }
}

export default ListPage;
