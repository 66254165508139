/* global chrome */

import axios from 'axios';
import urljoin from 'url-join';

import config from '../../config';

const instance = axios.create({
  withCredentials: true,
});

const invalidationMessage = {
  invalidate: true,
};

class Auth {
  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

  static async isUserAuthenticated() {
    const token = Auth.getToken();
    if (token === null) return false;

    try {
      const response = await instance.get(config.baseURL + '/protected');
      return response.data.success;
    } catch (error) {
      Auth.logout();
      return false;
    }
  }

  static deauthenticateUser() {
    localStorage.removeItem('token');
  }

  static handleChromeExtension(data) {
    try {
      chrome.runtime.sendMessage(config.chromeID, data, function (response) {});
      window.postMessage(data, '*');
    } catch (error) {
      console.warn('No chrome runtime detected');
    }
  }

  static async logout() {
    const respose = await instance.get(config.baseURL + '/logout');
    Auth.handleChromeExtension(invalidationMessage);
    Auth.deauthenticateUser();
  }

  static getToken() {
    return localStorage.getItem('token');
  }
}

export default Auth;
