/* global chrome */

import React from 'react';
import style from './index.module.scss';

import SignUp from '../SignUp';

import chrome from './assets/chrome.svg';

class Content extends React.Component {
  render() {
    return (
      <div className={style.Content}>
        <div className={style.ContentExplanation}>
          <img src={chrome} />
        </div>
        <SignUp />
      </div>
    );
  }
}

export default Content;
