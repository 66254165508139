import React from 'react';
import style from './index.module.scss';

class ErrorPage extends React.Component {
  render() {
    return <div className={style.ErrorPage}>ErrorPage</div>;
  }
}

export default ErrorPage;
