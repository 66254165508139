/* global chrome */

import React from 'react';
import style from './index.module.scss';

import GoogleAuth from '../../components/GoogleAuth';
import Auth from '../../modules/Auth';

import urljoin from 'url-join';

class ExtensionLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  async componentDidMount() {
    const response = await Auth.isUserAuthenticated();
    this.setState({
      authenticated: response != false,
    });
  }

  async logout() {
    await Auth.logout();
  }

  onSuccess() {
    window.close();
  }

  renderAuth() {
    if (!this.state.authenticated) {
      return <GoogleAuth onSuccess={this.onSuccess.bind(this)} />;
    } else {
      return (
        <div className={style.LogOut} onClick={this.logout.bind(this)}>
          Logout
        </div>
      );
    }
  }

  render() {
    return (
      <div className={style.ExtensionLoginPage}>
        <div className={style.ExtensionLoginPageContainer}>
          <div className={style.ExtensionLoginTitle}>
            FO<span className={style.ExtensionHighlight}>CC</span>USED
          </div>
          <div className={style.ExtensionLoginAuthContainer}>
            {this.renderAuth()}
          </div>
        </div>
      </div>
    );
  }
}

export default ExtensionLoginPage;
