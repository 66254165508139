/* global chrome */

import React from 'react';

import axios from 'axios';
import GoogleLogin from 'react-google-login';
import urljoin from 'url-join';

import Auth from '../../modules/Auth';
import config from '../../config';

class GoogleAuth extends React.Component {
  constructor(props) {
    super(props);
    this.instance = axios.create({
      withCredentials: true,
    });
  }

  static authParcel(token) {
    return {
      message: 'authorization',
      token: token,
    };
  }

  successGoogle(response) {
    ((self) => {
      self.instance
        .get(config.baseURL + '/google/token', {
          params: {
            access_token: response.accessToken,
          },
        })
        .then(function (res) {
          Auth.authenticateUser(response.accessToken);

          // TODO: add this to a common messaging location
          try {
            chrome.runtime.sendMessage(
              config.chromeID,
              GoogleAuth.authParcel(response.accessToken),
              function (response) {}
            );
            window.postMessage({ accessToken: response.accessToken }, '*');
          } catch (error) {
            console.warn('No chrome runtime detected');
          } finally {
            if (self.props.onSuccess) self.props.onSuccess(response);
          }
        });
    })(this);
  }

  successFailure(response) {
    if (this.props.onFailure) this.props.onFailure(response);
  }

  /*
    TODO: Custom Style Google Button
    render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
    )}
  */
  render() {
    return (
      <GoogleLogin
        clientId={config.googleClientID}
        buttonText="Login with Google"
        onSuccess={this.successGoogle.bind(this)}
        onFailure={this.successFailure.bind(this)}
        cookiePolicy={'single_host_origin'}
      />
    );
  }
}

export default GoogleAuth;
