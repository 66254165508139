import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import ExtensionLoginPage from './pages/ExtensionLoginPage';
import TodoPage from './pages/TodoPage';
import TestPage from './pages/TestPage';
import ListPage from './pages/ListPage';

import PrivatePolicy from './legal/PrivatePolicy';
import TermsAndConditions from './legal/TermsAndConditions';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => <HomePage {...props} />} />
          <Route
            exact
            path="/extension_login"
            render={(props) => <ExtensionLoginPage {...props} />}
          />
          <Route
            exact
            path="/private_policy"
            render={(props) => <PrivatePolicy {...props} />}
          />
          <Route
            exact
            path="/terms_of_service"
            render={(props) => <TermsAndConditions {...props} />}
          />
          <Route
            exact
            path="/todo"
            render={(props) => <TodoPage {...props} />}
          />
          <Route
            exact
            path="/test"
            render={(props) => <TestPage {...props} />}
          />
          <Route
            exact
            path="/test_list"
            render={(props) => <ListPage {...props} />}
          />
          <Route render={(props) => <ErrorPage {...props} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
